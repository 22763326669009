import React from "react";
import AuthContext from "./AuthContext";

const usePontosVenda = () => {
  const [pontosVenda, setPontosVenda] = React.useState([]);
  const { state } = React.useContext(AuthContext);

  React.useEffect(() => {
    setPontosVenda(
      state.allinfo.filter((item) => item.CodPontoVenda !== 17393)
    );
  }, [state.codPontoVenda, state.allinfo]);

  return pontosVenda;
};

export default usePontosVenda;
